import * as React from 'react';
import {Img} from 'src/components/Img';
import {Link} from 'src/components/Link';
import {Route} from 'react-router';
import {
  LeadParagraphContainer,
  LeadParagraphHeading,
  LeadParagraph,
  LeadProjectHeading,
  LeadProjectLocation,
  LeadProjectAddress,
} from 'src/components/LeadParagraph';
import {
  PageBackground,
  PageSection,
  PageSectionHeader,
  LeadAndPageContainer,
  PageSectionParagraph,
} from 'src/components/PageSection';
import {DotTimeline, TimelineDate, TimelineElement, TimelineLabel} from 'src/components/DotTimeline';
import {Highlight} from 'src/components/Highlight';
import styled from 'src/theme';
import {PageImage} from 'src/components/PageImage';
import {Footer} from './Footer';
import {Reg506c} from 'src/components/Reg506c';
import {DoughnutChart} from 'src/components/DoughnutChart';
import {projectsPort} from 'src/lib/isLive';

const ProjectFirmLogo = styled(Img)`
  height: 50px;
  margin: 5px;
`;

const GrayDotTimeline = styled(DotTimeline)`
    background-color: rgba(0,0,0,0.1);
    padding: 0.5rem 0 0.5rem 0;
`;

const StrategyTableContainer = styled.div`
    width: 100%;
    margin-bottom: 1.0rem;
`;

const EquityStrategyTableRow = styled.div`
    line-height: 1.5rem;
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: row;
    margin: auto;
    flex-wrap: nowrap;
`;

const StrategyTableCell = styled.div`
    display: inherit;
    width: 100%;
    flex: 0 0 50%;
    align-self: center;
    padding: 0.5rem;
`;

const LeftCell = styled(StrategyTableCell)`
    justify-content: flex-start;
`;

const RightCell = styled(StrategyTableCell)`
    justify-content: center;

    @media screen and (min-width: 420px) {
        justify-content: flex-end;
    }
`;

const ParternshipGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PartnershipContainer = styled.div`
  flex: 1;
  min-width: 100%;

  @media screen and (min-width: 900px) {
    min-width: 50%;
    max-width: 50%;
  }
`;

const PartnershipBoxLink = styled(Link)`
  display: block;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 5px;
  margin: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);

  text-align: center;
`;

const PartnershipBoxImage = styled.div`
  background-image: url(${({src}) => src || ''});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 15em;
`;

const SpinnerContainer = styled.div`
    position: relative;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-top: 1.5rem;
`;

const AugustaStreetLofts = (): JSX.Element => {
  const [spinnerLoaded, setSpinnerLoaded] = React.useState(false);

  return (
    <>
      <PageBackground>
        <Img src={'investments/sanantoniofront1.png'} asDivWithBackground/>
        <LeadAndPageContainer>
          <LeadParagraphContainer topOverride='10rem'>
            <LeadProjectHeading>
                        Augusta Street Lofts
            </LeadProjectHeading>
            <LeadProjectLocation>
                        819 Augusta St.
            </LeadProjectLocation>
            <LeadProjectAddress>
                        San Antonio, TX  78215
            </LeadProjectAddress>
            <ProjectFirmLogo src={'corporate/stillwater-logo.png'}/>
            <ProjectFirmLogo src={'corporate/hlr-logo.jpg'}/>
          </LeadParagraphContainer>
          <Reg506c>
            <PageSection mobileTop='10rem'>
              <PageSectionHeader marginTop='2.0rem'>
                            Project <Highlight>Overview</Highlight>
              </PageSectionHeader>
              <PageSectionParagraph>
                            The Project is a five-level wrap apartment complex in downtown San Antonio, Texas.
                            The 262-unit apartment complex will offer a best-in-class set of community amenities
                            and modern finishes. The Project is located between San Antonio&apos;s Central Business
                            District (&quo;CBD&quo;) and the vibran Pearl District. The residents of Project will have
                            access to major employment, retail, entertainment, and abundant outdoor recreational
                            opportunities. The San Antonio CBD is a short walk away and offers an employment base
                            of over 140,000 jobs. The site is also only two blocks from San Antonio&apos;s famous
                            Riverwalk path providing convenient access to the Historic Downtown Riverwalk and the
                            Museum Reach areas.
              </PageSectionParagraph>
              <br/>
              <PageSectionParagraph>
                            The Augusta Street Lofts offer residents of San Antonio an apartment community featuring
                            a mix of 202 studio units and one-bedroom units, and 60 two-bedroom units with an overall
                            average unit size of 797 square feet.
              </PageSectionParagraph>
              <br/>
              <PageSectionParagraph>
                            The construction of the Project is anticipated to last approximately 22 months. Upon
                            completion, Project amenities will include a large resort-style pool courtyard with
                            pergolas and grilling stations, a state-of-the-art two-story fitness center, a chic
                            resident lounge and a six-level garage providing secure parking. Other unit amenities
                            include stainless steel appliances, quartz countertops, custom cabinetry, faux wood
                            floors, and modern finishes.
              </PageSectionParagraph>
              <br/>
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionParagraph>
                <PageImage src='investments/augusta_centralpool.png'/>
              </PageSectionParagraph>
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionHeader marginTop='2.0rem'>
                <Highlight>Equity</Highlight> Investment
              </PageSectionHeader>
              <PageSectionParagraph>
                            The principals of NHK Capital Partners have established a significant business relationship
                            with Stillwater Capital Investments, LLC (&quo;Stillwater&quo;) through collaboration on seven
                            previous multifamily residential developments in the State of Texas. Building upon this
                            series of successful collaborations with Stillwater, NHK Augusta, LP will make an equity
                            investment into a joint venture company with Stillwater to provide a portion of the equity
                            financing for the development and construction of the Project.
              </PageSectionParagraph>
              <br/>
              <PageSectionParagraph>
                            Historically, investments in real estate infrastructure tend to have a low correlation with
                            traditional asset classes, such as stocks and bonds. This low correlation adds diversification
                            to an investor&apos;s portfolio, which helps smooth out returns and lower the portfolio&apos;s overall
                            level of volatility. The expected term of the equity investment in the Project is up to five
                            years to ensure that the developer will be able to utilize the funds long enough to complete
                            the construction of the Project, reach stabilization of operations, and realize a return on the
                            investment.
              </PageSectionParagraph>
              <br/>
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionParagraph>
                <PageImage src='investments/augusta_roofterrace.png'/>
              </PageSectionParagraph>
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionHeader marginTop='2.0rem'>
                            Project <Highlight>Details</Highlight>
              </PageSectionHeader>
              <GrayDotTimeline>
                <TimelineElement>
                  <TimelineLabel>
                                Construction<br/>
                                Commence
                  </TimelineLabel>
                  <TimelineDate>
                                Mar 2019
                  </TimelineDate>
                </TimelineElement>
                <TimelineElement>
                  <TimelineLabel>
                                Construction<br/>
                                Completion
                  </TimelineLabel>
                  <TimelineDate>
                                Feb 2021
                  </TimelineDate>
                </TimelineElement>
                <TimelineElement>
                  <TimelineLabel>
                                &nbsp;<br/>
                                Stabilization
                  </TimelineLabel>
                  <TimelineDate>
                                Sept 2021
                  </TimelineDate>
                </TimelineElement>
                <TimelineElement>
                  <TimelineLabel>
                                &nbsp;<br/>
                                Target Exit
                  </TimelineLabel>
                  <TimelineDate>
                                Sept 2023
                  </TimelineDate>
                </TimelineElement>
              </GrayDotTimeline>
              <StrategyTableContainer>
                <EquityStrategyTableRow>
                  <LeftCell>Target Investment Term:</LeftCell>
                  <RightCell>5 Years</RightCell>
                </EquityStrategyTableRow>
                <EquityStrategyTableRow>
                  <LeftCell>Minimum Investment:</LeftCell>
                  <RightCell>$100,000</RightCell>
                </EquityStrategyTableRow>
                <EquityStrategyTableRow>
                  <LeftCell>Target IRR:</LeftCell>
                  <RightCell>12.2%</RightCell>
                </EquityStrategyTableRow>
                <EquityStrategyTableRow>
                  <LeftCell>Target Equity Multiple:</LeftCell>
                  <RightCell>1.57x</RightCell>
                </EquityStrategyTableRow>
              </StrategyTableContainer>
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionParagraph>
                <a href='https://goo.gl/maps/1Mvo5LoGu582Y45D8' target='_blank' rel='noopener noreferrer'>
                  <PageImage src='investments/augustalocationmap.png'/>
                </a>
              </PageSectionParagraph>
            </PageSection>
            <PageSection mobileTop='10rem'>
              { spinnerLoaded &&
                <DoughnutChart
                  width={300}
                  height={300}
                  outerRadius={150}
                  innerRadius={75}
                  colors={['#ff8888',
                    '#c91111',
                    '#a70000',
                    '#fb1111',
                  ]}
                  data={[{value: 1200126},
                    {value: 10801136},
                    {value: 8075000},
                    {value: 27260000},
                  ]}
                  legend={['Stillwater Equity',
                    'NHK Equity',
                    'Mezzanine Debt',
                    'Senior Debt',
                  ]}
                /> ||
                <SpinnerContainer>
                  <img onLoad={(): void => setSpinnerLoaded(true)} src='/spinner.gif'/>
                </SpinnerContainer>
              }
            </PageSection>
            <PageSection mobileTop='10rem'>
              <PageSectionHeader marginTop='2rem'>
                            Project Developer
              </PageSectionHeader>
              <PageSectionParagraph centered={true}>
                            Stillwater Capital Investments, LLC is a fully-integrated, private real
                            estate company with expertise in sourcing, underwriting, developing,
                            acquiring, and managing a wide spectrum of real estate products.
                            For additional information please visit
                {' '}
                <a href='http://www.stillwatercap.com' target='_blank' rel='noopener noreferrer'>
                            Stillwater Capital&apos;s Website
                </a>
              </PageSectionParagraph>
              <br/>
            </PageSection>
          </Reg506c>
          <PageSection mobileTop='10rem'>
            <Footer/>
          </PageSection>
        </LeadAndPageContainer>
      </PageBackground>
    </>
  );
};

const MainInvestmentsPage = (): JSX.Element => {
  const [currentProjects, setCurrentProjects] = React.useState([]);
  const [pastProjects, setPastProjects] = React.useState([]);

  React.useEffect(() => {
    fetch(`${API_URL}:${projectsPort()}/api/projects/list`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCurrentProjects(data.projects);
        }
      });
    fetch(`${API_URL}:${projectsPort()}/api/projects/past/list`)
      .then((response) => response.json())
      .then(async (data) => {
        if (data.success) {
          setPastProjects(data.projects);
        }
      });
  }, []);
  return (<>
    <PageBackground>
      <Img src={'stock/cityskyline.png'} asDivWithBackground/>
      <LeadAndPageContainer>
        <LeadParagraphContainer>
          <LeadParagraphHeading>
            <span>Asset Management</span>
          </LeadParagraphHeading>
          <LeadParagraph>
            NHK Capital Partners sources and structures investment opportunities in commercial real estate,
            which otherwise would be traditionally reserved for institutional investors. The investment
            offerings are meticulously selected to both meet the highest standards of the Hogan family,
            as well as our savvy family of investors. We understand our client’s wide range of investment
            goals, from those who want to maximize long term capital gains, or those that desire a risk-adjusted
            steady flow of income throughout the investment period. We strive to research and evolve our product
            offerings to find the most suitable developments that provide optimal risk-adjusted returns to our investors.
          </LeadParagraph>
        </LeadParagraphContainer>
        <PageSection>
          <PageSectionHeader marginTop='2.5rem'>
            <Highlight>Equity</Highlight> Investment
          </PageSectionHeader>
          <PageSectionParagraph>
            Equity ownership interests are held by the partnership in the target projects. The objective of our
            equity partnerships are to provide an investment vehicle that maximizes the return profile by seeking
            long-term capital appreciation. This investment strategy intends to provide more ambitious gains for
            calculated risk bearing investors, as such gains would typically be realized at the end of the investment
            term. Target hold periods for our equity investments are anticipated to be under 5 years.
          </PageSectionParagraph>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='2.5rem'>
            <Highlight>Debt</Highlight> Investment
          </PageSectionHeader>
          <PageSectionParagraph>
            Our debt investment partnerships provide funding into institutional-grade commercial real estate properties
            generally as mezzanine loans or preferred equity. The objective of our debt investment partnerships is to
            provide a risk-adjusted investment vehicle that provides a modest and steady flow of income throughout the
            investment period. Target hold periods for our debt investment partnerships are typically under 5 years.
          </PageSectionParagraph>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='2.5rem'>
            Current Partnerships
          </PageSectionHeader>
          <Reg506c>
            <PageSectionParagraph>
              <ParternshipGroup>
                {currentProjects.map((project: any): JSX.Element => (
                  <PartnershipContainer
                    key={project.slug}
                  >
                    <PartnershipBoxLink
                      onClick={(): void => window.scrollTo(0, 0)}
                      to={`investments/${project.slug}`}
                    >
                      <PartnershipBoxImage src={project.boxmedia}/>
                      {project.name.substring(5, project.name.length - 3)}
                    </PartnershipBoxLink>
                  </PartnershipContainer>
                ))}
              </ParternshipGroup>
            </PageSectionParagraph>
          </Reg506c>
        </PageSection>
        <PageSection>
          <PageSectionHeader marginTop='2.5rem'>
            Past Partnerships
          </PageSectionHeader>
          <PageSectionParagraph>
            <ParternshipGroup>
              {pastProjects.map((project: any): JSX.Element => (
                <PartnershipContainer
                  key={project.slug}
                >
                  <PartnershipBoxLink
                    onClick={(): void => window.scrollTo(0, 0)}
                    to={`investments/past/${project.slug}`}
                  >
                    <PartnershipBoxImage src={project.boxmedia}/>
                    {project.name.substring(5, project.name.length - 3)}
                  </PartnershipBoxLink>
                </PartnershipContainer>
              ))}
            </ParternshipGroup>
          </PageSectionParagraph>
        </PageSection>
        <PageSection>
          <Footer/>
        </PageSection>
      </LeadAndPageContainer>
    </PageBackground>
  </>);
};

const ChartContainer = styled.div`
    width: 12rem;
    height: 12rem;
    background-color: #000;
`;

const DebtInvestmentPage = (): JSX.Element => {
  return (
    <PageBackground>
      <Img src={'investments/sanantonio5.png'} asDivWithBackground/>
      <PageSection mobileTop='0rem'>
        <PageSectionHeader>
          Project Overview
        </PageSectionHeader>
        <ChartContainer>
          <svg
            width="200px"
            height="200px"
          >
            <circle
              r="25"
              cx="100"
              cy="100"
              fill="white"
              stroke="#c91111"
              strokeWidth="15"
              strokeDasharray="100 158"
              transform="rotate(40 100 100)"
            />
            <circle
              r="25"
              cx="100"
              cy="100"
              fill="rgba(0, 0, 0, 0.0)"
              stroke="#ff8888"
              strokeWidth="15"
              strokeDasharray="80 158"
              strokeDashoffset="50%"
              transform="rotate(-50 100 100)"
            />
            <circle
              r="25"
              cx="100"
              cy="100"
              fill="rgba(0, 0, 0, 0.0)"
              stroke="#a70000"
              strokeWidth="15"
              strokeDasharray="70 158"
              strokeDashoffset="50%"
            />
            <circle
              r="25"
              cx="100"
              cy="100"
              fill="rgba(0, 0, 0, 0.0)"
              stroke="#fb1111"
              strokeWidth="15"
              strokeDasharray="35 158"
              transform="rotate(-25 100 100)"
            />
          </svg>
        </ChartContainer>
      </PageSection>
      <PageSection mobileTop='0rem'>
        <Footer/>
      </PageSection>
    </PageBackground>
  );
};

export const InvestmentsPage = (): JSX.Element => {
  return (
    <>
      <Route exact={true} path='/investments' render={(): JSX.Element => <MainInvestmentsPage/>}/>
      <Route exact={true} path='/investments/debt-partnership' render={(): JSX.Element => <DebtInvestmentPage/>}/>
      <Route exact={true} path='/investments/augusta-street-lofts' render={(): JSX.Element => <AugustaStreetLofts/>}/>
    </>
  );
};
